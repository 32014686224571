.dashboard {
  min-height: 100vh;
  overflow-x: hidden;

  .ant-layout-header {
    background: white;
  }

  .top {
    padding: 0 $padding;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 290px;

    .brand-logo {
      height: 40px;
      max-width: 100px;
      object-fit: contain;
      object-position: left;
    }

    .theme-switch {
      width: 130px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
    }
  }

  .component {
    padding: $padding;

    // Prevent grid blowout
    min-width: 0;
  }

  .main-content {
    background: $gray1;
    padding: $padding;
    min-width: 320px;
  }

  .dashboard-top {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        margin: 0;
      }

      button {
        padding: 10px 40px;
      }
    }
  }

  .stories {
    .table {
      margin-top: 1rem;

      .ant-btn {
        padding: 5px 35px;
      }

      .ant-table-wrapper.active {
        .ant-table-tbody {
          .ant-table-cell {
            padding-top: 24px;
          }
        }
      }

      .ant-table {
        font-size: 15px;
      }

      .ant-table-thead > tr > th {
        background: $gray1;
        color: $gray5;
      }

      .story-title {
        display: flex;
        align-items: center;

        .clock {
          position: absolute;
          min-width: 90px;
          top: 0;
          left: 0;
          padding: 2px 10px;
          border-radius: 0 0 10px 0;

          &.red {
            background: $primaryFaded;
            color: $primary;
          }

          &.blue {
            background: $secondaryFaded;
            color: $secondary;
          }
 
          &.green {
            background: $tertiaryFaded;
            color: $tertiary;
          }
        }

        h2 {
          line-height: 15px;
          margin-bottom: 0;
          margin-left: 1rem;
        }
      }

      .button-container {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .superfans {
    position: relative;

    .header {
      height: 55px;
      display: flex;
      align-items: center;
    }

    .view-toggle {
      position: absolute;
      right: 0;
      z-index: 1;
      color: $gray4;
      padding-left: 5px;


      svg {
        transition: color ease 0.3s;
        width: 32px;
        height: 32px;
        margin-right: 1rem;
        cursor: pointer;
      }

      .active {
        color: $secondary;
      }
    }

    .table {
      .superfan-profile {
        display: flex;
        align-items: center;

        img, svg {
          width: 45px;
          height: 45px;
        }

        h2 {
          line-height: 15px;
          margin-bottom: 0;
          margin-left: 1rem;
        }
      }

      .quote {
        max-width: 400px;
      }

      .button-container {
        display: flex;
        justify-content: flex-end;
      }

      .menu {
        margin-top: 5px;
      }
    }

    .grid {
      .superfan-card {
        background: white;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;
        height: 100%;

        .profile-img {
          width: 60px;
          height: 60px;
        }

        svg.profile-img {
          margin-bottom: -6px;
        }

        .superfan-profile {
          text-align: center;
        }

        .quote {
          height: 106px;
        }
      }
    }

    .drawer-container {
      position: relative;
      overflow: hidden;
      height: 65px;
    }
  }

  .superfan-detail, .story-detail {
    .content-box {
      .name {
        display: flex;
        align-items: center;

        svg {
          color: $secondary;
        }

        h1 {
          margin-bottom: 0;
          margin-left: 1rem;
        }

        img {
          width: 40px;
        }

        .expires {
          margin-left: 1rem;
        }
      }

      .profile-info {
        div {
          max-width: 80%;

          a {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 80%;
          }
        }

        svg {
          color: $secondary;
          width: 25px;
          margin-right: 10px;
        }
      }

      .analytics {
        .metric {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: solid $gray3 1px;
          border-radius: 5px;
          padding: 12px 0 8px;

          & > * {
            margin: 0;
          }

          svg {
            color: $secondary;
            height: 24px;
            width: 24px;
            margin-bottom: 3px;
          }

          h1 {
            margin-left: 10px;
            color: $secondary;
          }

          span {
            margin-left: 5px;
          }
        }
      }
    }

    .quote {
      margin-top: 4px;
      .icon {
        position: absolute;
        width: 28px;
        margin-top: -3px;
      }

      .copy {
        padding: 0 1rem;
        z-index: 1;
        position: relative;
      }
    }
  }

  .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
    background: transparent;
    top: 15px;
    left: 12px;

    svg {
      height: 25px;
      width: 25px;
    }
  }

  .statistics {
    height: 100%;
    display: grid;
    grid-gap: 32px;
    grid:
      'dashboard-top  dashboard-top'      max-content
      'reach          superfans'          400px
      'value          quotes'             1fr
      / 2fr 1fr;

    @media (max-width: 1199px) {
      grid:
      'dashboard-top'      max-content
      'reach'              1fr
      'superfans'          1fr
      'value'              1fr
      'quotes'             1fr;

      .component {
        margin-left: -$padding;
        margin-right: -$padding;
      }
    }

    .component {
      background: white;
      padding: $padding;
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.02);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
    }

    .legend {
      display: flex;
      align-items: baseline;
      margin: 0.7rem 0;

      p {
        margin-left: 5px;
      }

      .thumbnail {
        width: 12px;
        height: 12px;
        border-radius: 3px;

        &:not(:first-child) {
          margin-left: 10px;
        }
      }
    }

    .date-selector {
      display: flex;
      justify-content: flex-end;
      height: 42px;
    }

    .quotes {
      .quote {
        display: flex;
        flex-direction: column;
        max-width: 300px;
        margin: auto;
      }

      .profile {
        width: 64px;
        height: 64px;
        align-self: center;
      }

      .icon {
        position: absolute;
        top: 66px;
        z-index: -1;
        width: 28px;
      }

      .copy {
        padding: 0 1rem;
        margin-top: 1rem;
      }
    }

    .superfans {
      .ant-table {
        height: 316px;
        overflow: scroll;
      }

      .ant-table-tbody > tr > td {
        padding: 8px;
      }
    }
  }
}

// Tablet styles
@media (max-width: 991px) {
  .dashboard {
    .side-panel {
      .logo {
        margin-top: 36px;
      }

      .ant-menu {
        margin-top: 1rem;
      }
    }

    .top {
      margin-left: 30px;
    }
  }
}


// Small mobile styles
@media (max-width: 399px) {
  .dashboard {
    .top {
      justify-content: flex-end;

      .brand-logo {
        display: none;
      }
    }
  }
}

@media (min-width: $md) {
  .message {
    max-height: 378px;
  }
}

.account, .onboarding-modal {
  .dropzone-container {
    width: 210px;

    .dropzone {
      background-size: cover !important;
    }
  }
}

.video-message {
  .progress {
    max-width: 250px;
  }
}
