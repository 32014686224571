// Also edit colors for Ant Design components in antd-theme.less and build custom-antd.scss
// https://dev.to/agmm/simple-way-of-modifying-ant-design-default-theme-3g4h

$primary: #ff6056;
$primaryDark: #e25147;
$primaryLight: #F9B9B5;
$primaryFaded: #FCDDDB;
$primaryMoreFaded: rgba(255, 131, 131, 0.04);

$secondary: #3632F5;
$secondaryDark: #2f2cda;
$secondaryLight: #EFEEFF;
$secondaryFaded: #D9D8FE;
$secondaryMoreFaded: rgba(54, 50, 245, 0.02);
$secondaryMoreFaded2: rgba(54, 50, 245, 0.03);

$tertiary: #29C779;
$tertiaryLight: #74D69B;
$tertiaryFaded: #C0E9D5;
$tertiaryFaded2: rgba(41, 199, 121, 0.05);

$gray0: #FAFAFA;
$gray1: #F8F8F9;
$gray2: #EBECF0;
$gray3: #E2E2E2;
$gray4: #B0B0B0;
$gray5: #808080;
$gray6: #383838;

$text: black;
$light-text: $gray4;
$dark-text: $gray5;

$shadow: rgba(166, 171, 189, 0.5);

// Gradients
$background-gradient: linear-gradient(
  160deg,
  rgba(240, 241, 244, 1) 0%,
  rgba(227, 229, 234, 1) 100%
);
$blue-gradient: linear-gradient(90deg, #6dc6fb 0%, #4b38ff 100%);

:export {
  primary: $primary;
  primaryDark: $primaryDark;
  primaryLight: $primaryLight;
  secondary: $secondary;
  secondaryLight: $secondaryLight;
  tertiary: $tertiary;
  tertiaryFaded: $tertiaryFaded;
  gray1: $gray1;
  gray2: $gray2;
  gray3: $gray3;
  gray4: $gray4;
  gray5: $gray5;
  gray6: $gray6;
  lightText: $light-text;
}

