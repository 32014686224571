@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;0,600;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600&display=swap');

body {
  color: $text;
  font-family: 'Work Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Poppins', sans-serif;
}

.font-secondary {
  font-family: 'Poppins', sans-serif;
}

h1,
h2 {
  font-weight: bold;
  margin-bottom: 1rem;
}

h1 {
  font-size: 25px;
  line-height: 35px;
}

h2 {
  font-size: 15px;
  line-height: 25px;
}

h3 {
  font-size: 13px;
}

.center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.underline {
  text-decoration: underline;
}

.align-left {
  text-align: left;
}

.mini {
  font-size: 8px;
}

.small {
  font-size: 10px;
}

.normal {
  font-size: 14px;
}

.large {
  font-size: 16px;
}

.mega-large {
  font-size: 20px;
}

.super-large {
  font-size: 25px;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.regular {
  color: $text !important;
}

.primary {
  color: $primary !important;
}

.secondary {
  color: $secondary !important;
}

.tertiary {
  color: $tertiary !important;
}

.light {
  color: $light-text !important;
}

.white {
  color: white !important;
}

.gray-5 {
  color: $gray5
}

.highlight {
  background-color: $primary;
  color: white;
  padding: 0 5px;

  &.primary {
    background: $primaryFaded;
    color: $primary;
    border-radius: 2px;
  }

  &.secondary {
    background: $secondaryFaded;
    color: $secondary;
    border-radius: 2px;
  }
}

.nowrap {
  white-space: nowrap;
}

.link {
  color: $text;
  text-decoration: underline;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
}

.pre-wrap {
  white-space: pre-wrap;
}

.blink {
  animation: blink 0.3s linear 2;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
