.story-editor {
  .dropzone-container {
    width: 180px;
  }

  .dropzone {
    padding: 1rem 2rem;
  }

  .posts {
    display: flex;
    padding-bottom: 1rem;

    .post {
      .anticon-close-circle {
        position: absolute;
        top: 5px;
        right: 5px;
        color: gray;
        background: white;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        cursor: pointer;
        z-index: 1;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      img {
        width: 120px;
        height: 220px;
        object-fit: cover;
      }

      video {
        height: 220px;
      }
    }
  }

  .buttons-container {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
  }

  .hashtags {
    display: flex;

    & > :not(:first-child) {
      margin-left: 10px;
    }

    .hashtag {
      display: flex;
      align-items: center;
    }

    input {
      margin-bottom: 0;
    }
  }

  .categories {
    display: flex;
    flex-wrap: wrap;

    .category {
      cursor: pointer;
      margin-bottom: 10px;
      color: $gray5;
      border-color: $gray5;

      &.selected {
        color: white;
        background: $secondary;
        border-color: $secondary;
      }
    }
  }

  .bynder-image {
    width: 250px;
    height: 140px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &.selected {
      border: 2px solid $secondary;
      border-radius: 5px;
    }
  }
}
