.social-preview {
  width: 100%;
  position: relative;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  margin-top: 13%;

  .channel {
    position: absolute;
    left: 40%;
    width: 20%;
    top: -7%;
  }

  .phone {
    width: 112%;
    margin-left: -6%;
    margin-top: -5%;
    margin-bottom: -5%;
  }
}
