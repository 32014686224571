.ant-btn {
  border-radius: 5px;
  padding: 10px 30px;
  height: auto;
  font-family: 'Work Sans';
  text-shadow: none;
}

.ant-btn-primary {
  border: none;
  background-color: $primary;
  font-weight: bold;

  &:hover, &:focus {
    background-color: $primaryDark;
    border: none;
  }

  &[disabled], &[disabled]:hover {
    opacity: 0.4;
    color: white;
    background-color: $primary;
    border: none;
  }
}

.ant-btn-secondary {
  border-color: $primary;
  color: $primary;
}

.ant-btn-secondary:hover, .ant-btn-secondary:active, .ant-btn-secondary:focus {
  border-color: $primaryDark;
  color: $primaryDark;
}

.ant-dropdown-trigger {
  cursor: pointer;
}

.ant-btn-secondary.select {
  border-color: $secondary;
  color: $text;
  font-weight: 500;
  text-align: left;
  white-space: normal;

  &.selected {
    background-color: $secondary;
    color: white;
  }
}

.ant-btn-secondary.select:hover, .ant-btn-secondary.select:active, .ant-btn-secondary.select:focus {
  border-color: $secondaryDark;
}

.ant-dropdown-trigger {
  cursor: pointer;
}


.ant-carousel {
  .slick-dots {
    li button {
      background: $gray4;
      // width: 10px;
      // height: 10px;
      // border-radius: 50%;
    }

    li.slick-active button {
      background: $primaryLight;
    }
  }

  .slick-dots-bottom {
    bottom: 0;
  }

  .slick-slider.slick-initialized {
    padding-bottom: 2rem;
  }
}

.ant-tabs {
  margin-bottom: -18px;
  margin-top: 11px;

  .ant-tabs-bar {
    margin-bottom: 0;
    border-bottom: none;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: unset;
    font-weight: 600;
    outline: none;
  }

  .ant-tabs-nav-container {
    font-size: 16px;
  }
}

.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  background: transparent;
  top: 0;
  height: 40px;
}

.ant-menu-item-selected {
  background-color: transparent !important;

  h2 {
    color: $secondary !important;
  }
}

.ant-input, form input, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-input-affix-wrapper {
  padding: 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid $gray4;
  border-radius: 0;
  font-size: 16px;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:hover {
  border-color: unset;
  border-right-width: unset;
  outline: 0;
  box-shadow: none;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding-top: 0;
}

textarea.ant-input {
  border-radius: 7px;
  border: 1px solid $gray4;
  padding: 8px;
  height: 200px;
}

form input, .ant-input, .ant-input::placeholder, .ant-select-selection-item {
  color: $gray6;
  font-weight: bold;
}

textarea.ant-input, textarea.ant-input::placeholder {
  font-weight: 400;
}

textarea.ant-input::placeholder {
  color: $gray5;
}

.float-label {
  position: relative;
  margin-top: 14px;

  .label {
    color: $gray6;
    font-weight: bold;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    transition: 0.2s ease all;
  }

  .label-float {
    color: $gray5;
    top: -20px;
    left: 0 !important;
  }

  & > input:focus, & > .ant-select:focus {
    outline: none;
    box-shadow: none;
  }

  .ant-select {
    margin-top: 8px;
    min-width: 120px;
  }
}

.ant-space-vertical {
  width: 100%;
}

.ant-tag {
  font-size: 14px;
  font-weight: 600;
  color: $secondary;
  background: transparent;
  padding: 5px 20px;
  border: 1px solid $secondary;
  border-radius: 20px;
}

.table {
  .ant-btn-secondary {
    padding: 5px 35px;
  }
}

.dropzone {
  padding: 2rem;
  background: $secondaryMoreFaded;
  border-radius: 5px;
  border: 0.8px dashed $secondary;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  p {
    white-space: normal;
    padding: 10px;
    text-align: center;
  }
}

.ant-popover {
  button {
    font-size: 12px;
    padding: 5px 10px;
    font-weight: 500;
  }

  .ant-popover-message > .anticon {
    color: $light-text;
  }
}

.ant-progress-bg {
  background-color: $secondary;
}

.ant-card {
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.18);
}

.ant-slider-track {
  background-color: $secondary;
}

.ant-slider-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: -8px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  box-shadow: -1px 1px 11px rgba(0, 0, 0, 0.25);
}

.ant-slider-dot {
  position: absolute;
  top: -8px;
  width: 2px;
  height: 20px;
  margin-left: -1px;
  background-color: red;
  border: none;
  border-radius: 0;
  cursor: pointer;
}

.ant-slider-dot:first-child, .ant-slider-dot:last-child {
  margin-left: -1px;
}

.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
}

span.ant-btn-loading-icon {
  padding-right: 8px;
}