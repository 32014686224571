.side-panel {
  box-shadow: -3px 0px 9px rgba(0, 0, 0, 0.15);
  padding-top: 0;

  .logo {
    height: 100px;
    padding: 24px;
  }

  .ant-menu {
    margin-top: 3rem;

    .ant-menu-item {
      height: unset;
    }

    .tab {
      height: 49px;
      display: flex;
      align-items: center;
      cursor: pointer;

      h2 {
        transition: color 0.5s;
        margin: 0;
        font-weight: 500;
        color: $light-text;
      }

      .marker {
        position: absolute;
        width: 4px;
        height: 49px;
        left: 0;
        background-color: $secondary;
        transition: opacity 0.5s;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        opacity: 0
      }

      &.active {
        h2 {
          color: $secondary;
          font-weight: 600;
        }

        .marker {
          opacity: 1;
        }
      }
    }
  }
}
