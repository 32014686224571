.home-container {
  min-height: 100vh;

  @media (max-width: $sm) {
    .component {
      padding-top: 0;
    }
  }

  .ant-layout {
    background-color: white;
  }

  .ant-menu-vertical {
    border-right: none;
  }

  header {
    .ant-menu-horizontal, .ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected, .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
      border-bottom: none;
    }

    img {
      width: 65px;
    }
  }

  @media (max-width: 918px) {
    header.ant-layout-header.bg-white {
      display: flex;
      justify-content: center;
      height: 48px;
    }
  }

  .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
    top: 32px;
    left: 17px;
  }

  .anticon-bars svg {
    width: 27px;
    height: 27px;
  }

  h1.title {
    font-size: 44px;
    line-height: 52px;
    margin-top: 5rem;
    margin-bottom: 2rem;

    @media (max-width: $sm) {
      margin-top: 1rem;
    }
  }

  .hero {
    text-align: right;

    img {
      height: 515px;
    }
  }

  .home {
    .read-more {
      width: 137px;
      transform: rotate(-90deg);
      transform-origin: top left;
      margin-left: 8px;
      position: absolute;
      bottom: -92px;

      p {
        text-align: right;
        margin-bottom: -5px;
      }

      svg {
        color: $secondary;
      }
    }

    .brands {
      text-align: right;

      @media (min-width: $sm) {
        width: 500px;
      }

      img {
        width: 68px;
      }
    }

    .top-overlay {
      margin-top: -20px;
      width: 100%;

      @media (min-width: $sm) {
        margin-top: -100px;
      }

      @media (min-width: $md) {
        margin-top: -130px;
      }
    }

    .how-it-works {
      padding: $padding;
      display: flex;
      align-items: center;

      @media (min-width: $lg) {
        height: 300px;
        &:not(:first-child) {
          margin-top: 10rem;
        }
      }

      img {
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-right: -10px;

        @media (min-width: $lg) {
          margin-top: -150px;
        }

      }
    }

    .how-it-works-left {
      img {
        @media (min-width: 1300px) {
          margin-left: 90px;
          margin-right: -110px;
        }
      }
    }

    .how-it-works-right {
      img {
        @media (min-width: 1300px) {
          margin-right: 90px;
          margin-left: -110px;
        }
      }
    }

    .ripple-profile {
      width: 100px;
      height: 100px;
    }

    .quote {
      margin: auto;
      margin-top: -147px;
      max-width: 800px;
      margin-bottom: 4rem;

      @media (max-width: $md) {
        margin-top: -80px;
      }

      svg {
        width: 40px;
        height: 40px;
      }

      h1 {
        text-align: center;
        font-size: 38px;
        line-height: 38px;
        font-weight: 600;

        @media (max-width: $md) {
          font-size: 25px;
          line-height: 35px;
        }
      }
    }
  }

  .about {
    .ripple {
      width: 270px;
      svg {
        margin: 0 47px;
      }

      .ripple-children {
        align-items: flex-start;
      }
    }

    .team {
      .profile {
        width: 90px;

        &:not(:first-child) {
          margin-left: 1rem;
        }
      }
    }
  }

  .calculate-potential {
    h1.title {
      margin-top: 2rem;
    }

    .calculator-data {
      width: fit-content;
    }

    .pricing-card {
      @media (min-width: $md) {
        margin-top: 60px;
      }
    }
    .pricing {
      position: relative;
      filter: blur(6px);
    }

    .pricing-overlay {
      background: rgba(255, 255, 255, 0.7);
      position: absolute;
      height: calc(100% - 150px);
      width: 100%;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      h2 {
        font-size: 22px;
      }
    }
  }

  .join {
    .checklist-container {
      background: $secondaryMoreFaded2;
    }

    .previews {
      padding-left: 4%;
      padding-right: 4%;

      .social-preview {
        margin-bottom: 3rem;
      }
    }

    .brand-logo-container {
      width: 110px;
      height: 130px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}
.suggest-brand-modal {

  .small-brand-logo-container {
    width: 70px;
    height: 50px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
