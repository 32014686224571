.chart {
  height: 200px;
  flex-grow: 1;

  .tooltip {
    background: #FFFFFF;
    box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.1);
    padding: 10px 20px;

    p {
      margin: 0
    }
  }

  text {
    fill: $light-text !important;
  }
}
