.login {
  width: 100vw;
  height: 100vh;
  transition: all 0.5s;
  display: grid;
  column-gap: 0;
  grid:
    [row1-start] 'left right' 1fr [row1-end]
    / 45fr 55fr;

    @media (max-width: 767px) {
      grid:
        [row1-start] 'right' 1fr [row1-end]
        / 1fr;

      .left {
        display: none !important;
      }
    }

  .left, .right {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left {
    background-color: $secondary;
  }


  .login-box {
    width: 280px;
    margin-bottom: 4rem;

    .logo {
      width: 100px;
      margin-bottom: 2rem;
    }

    h1 {
      margin-bottom: 0;
    }

    .float-label {
      margin: 2rem 0;
    }

    .error {
      color: $primary;
      margin-top: 1rem;
    }
  }

  .brand-list {
    max-height: 400px;

    .brand-item, .brand-item:hover {
      transition: color 0.2s;
      color: $text;
    }
    .brand-item:hover {
      color: $secondary;
    }
  }
}
