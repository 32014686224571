.image-graphic {
  position: relative;
  width: max-content;
  margin-bottom: 20px;
  margin-left: 10px;

  .foreground, .background {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .foreground {
    position: relative;
    background-size: cover;
    background-position: center;
  }

  .background {
    background: $primaryLight;
    position: absolute;
    top: 5px;
    left: -5px;
  }
}
