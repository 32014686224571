// Custom Ant Design
@import "./custom-antd";
// To modify, edit antd-theme.less
// and execute lessc --js src/scss/antd-theme.less src/scss/custom-antd.scss
// More info: https://dev.to/agmm/simple-way-of-modifying-ant-design-default-theme-3g4h

@import "../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";

// Variables
$padding: 24px;
$side-panel-width: 250px;

// General
@import "./colors";
@import "./typography";
@import "./general";
@import "./controls";
@import "./charts";
@import "./table";

// Containers
@import "./home";
@import "./dashboard";
@import "./dashboard-home";

// Components
@import "./brand";
@import "./login";
@import "./side-panel";
@import "./story-editor";
@import "./header-graphic";
@import "./image-graphic";
@import "./social-preview";
@import "./superfan-signup";

body {
  transition: all 0.5s;
}

.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
