$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;


.container {
  width: 100%;
  align-self: center;
  margin: auto;
  padding: $padding;
}

@media (min-width: $xl) {
    .container {
        max-width: $xl;
    }
}

@media (min-width: $xxl) {
    .container {
        max-width: $xl;
    }
}

.container-x {
  padding-top: 0;
  padding-bottom: 0;
}

.xs-container {
  width: 100%;
  max-width: $xs;
  align-self: center;
  margin: auto;
  padding: $padding;
}

.sm-container {
  width: 100%;
  max-width: $sm;
  align-self: center;
  margin: auto;
  padding: $padding;
}

.md-container {
  width: 100%;
  max-width: $md;
  align-self: center;
  margin: auto;
  padding: $padding;
}

.m-auto {
  margin: auto;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.75rem;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 2rem !important;
}

.mt-6 {
  margin-top: 3rem !important;
}

.mt-7 {
  margin-top: 4rem !important;
}

.mt-8 {
  margin-top: 6rem !important;
}

.mt-10 {
  margin-top: 10rem !important;
}

.mt--3 {
  margin-top: -1rem !important;
}

.mt--5 {
  margin-top: -2rem;
}

.mt--6 {
  margin-top: -3rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.75rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 2rem;
}

.mb-6 {
  margin-bottom: 3rem;
}

.mb-7 {
  margin-bottom: 4rem;
}

.mb-9 {
  margin-bottom: 8rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mr-4 {
  margin-right: 1.5rem;
}

.ml-3 {
  margin-left: 1rem;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx--3 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.mx--padding {
  margin-left: -$padding !important;
  margin-right: -$padding !important;
}

.padding {
  padding: $padding !important;
}

.padding-2 {
  padding: calc(#{$padding} * 2) !important;
}

.padding-x-2 {
  padding-left: calc(#{$padding} * 2) !important;
  padding-right: calc(#{$padding} * 2) !important;
}

.margin-x--2 {
  margin-left: calc(-#{$padding} * 2) !important;
  margin-right: calc(-#{$padding} * 2) !important;
}

.padding-left {
  padding-left: $padding !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.pr-3 {
  padding-right: 1rem;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-4 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-fit-content {
  width: fit-content;
}

.overflow-x-hidden {
  overflow-x: hidden;
  overflow-y: visible;
}

.separator {
  width: 100%;
  height: 1px;
  margin-top: 1rem;
  background: $gray2;
}

.ant-layout {
  min-height: 100vh;
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-gap {
  gap: 1rem;
}

.d-inline-flex {
  display: inline-flex;
}

.direction-column {
  flex-direction: column;
}

.align-baseline {
  align-items: baseline;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start !important;
}

.align-end {
  align-items: flex-end !important;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.bg-secondary {
  background-color: $secondary;
}

.bg-white {
  background-color: white;
}

.bg-gray {
  background-color: $gray2;
}

.bg-gray-light {
  background-color: $gray1;
}

.bg-gray-super-light {
  background-color: $gray0;
}

.round {
  border-radius: 50%;
}

.break-all {
  word-break: break-all;
}

.full-width {
  width: 100vw;
}

.full-screen {
  width: 100vw;
  height: 100vh;
}

.z-1 {
  z-index: 1;
}

.content-box {
  margin-top: 1rem;
  background: white;
  box-shadow: 1px 4px 13px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: $padding;
}

ul.checklist {
  padding-left: 16px;
  li {
    position: relative;
    list-style-type: none;
    margin-bottom: 10px;
  }

  li:before {
    content: "";
    position: absolute;
    top: 5px;
    left: -16px;
    width: 12px;
    height: 12px;
    background-image: url('/img/check.svg');
    background-size: cover;
  }
}

.emoji-bullet {
  width: 35px;
  display: inline-block;

  &.double {
    width: 44px;
    text-align: right;
    margin-right: 6px;
  }
}

.custom-ul {
  margin-left: 35px;
  max-width: 235px;

  &.double {
    margin-left: 50px;
  }
}

.card {
  border-radius: 5px;
  border: 1px solid $gray3;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 25px;

  svg {
    height: 24px;
    width: 24px;
  }
}

.progress-bar {
  background-color: $gray3;
  border-radius: 5px;
  height: 8px;
  width: 100%;

  .progress {
    height: 100%;
    background-color: $secondary;
    border-radius: 5px;
  }
}

.quote {
  .icon {
    color: $secondaryFaded;
  }
}

.notification-count {
  background: $primary;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  font-family: 'Poppins', sans-serif;
}

.carousel-root {
  .carousel:not(:first-child) {
    display: none;
  }
}

.carousel {
  .slide {
    background: unset;
    text-align: unset;
  }

  .carousel-status {
    display: none;
  }

  .control-arrow:hover {
    background: none !important;
  }

  .control-arrow {
    opacity: 1;

    &:before {
      position: absolute;
      bottom: 11px;
    }
  }

  .control-next.control-arrow:before {
    border-left: 8px solid $primaryLight;
    right: -4px;
  }

  .control-prev.control-arrow:before {
    border-right: 8px solid $primaryLight;
    left: 4px;
  }


  .control-dots {
    .dot {
      box-shadow: none;
      opacity: 1;
      background-color: $gray4;

      &.selected {
        background-color: $primaryLight;
        height: 10px;
        width: 10px;
      }
    }

  }
}

video {
  max-width: 100%;
}

.ripple {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    color: $primaryMoreFaded;
    width: 100%;
    max-width: 400px;
  }

  .ripple-children {
    position: absolute;
    top: 50%;
    height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.ant-layout-footer {
  padding: 0;
  padding-top: 24px;

  div:not(:first-child) {
    @media (min-width: $sm) {
      text-align: right;
    }
  }
}

.modal-hide-footer {
  .ant-modal-footer {
    display: none;
  }
}

.modal-hide-close {
  .ant-modal-close {
    display: none;
  }
}

.bg-wave {
  background-image: url('/img/wave.svg');
  background-size: 100% 100%;
  height: 24px;

  // Bad fix, not sure why it doesn't align with div below on mobile
  @media (max-width: $sm) {
    margin-bottom: -4px;
  }
}

.profile-img {
  height: 50px;
  width: 50px;
}

.selected-superfans {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      background-color: $secondary;
      color: white;

      .ant-drawer-close {
        color: white;
        &:hover, &:focus, &:active {
          color: $gray2;
        }
      }

      .ant-drawer-body {
        padding: 18px 20px;
      }
    }
  }
}

.superfan-list {
  max-height: 500px;
  overflow: scroll;
}

.superfan-selector {
  .ant-drawer-content-wrapper {
    max-width: 100% !important;

    .superfan-list {
      max-height: calc(100% - 215px);
    }

    .bottom-full-width {
      position: absolute;
      bottom: $padding;
      width: calc(100% - (2 * #{$padding}));
    }
  }
}

.scrollbox {
  border: solid $gray3 1px;
  border-radius: 5px;
  padding: 8px;
  overflow: scroll;

  &.horizontal::-webkit-scrollbar {
    height: 8px;
  }

  &.vertical::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: $secondary;
    border-radius: 12px;
  }
}

.floater {
  .container {
    padding: $padding;
  }

  @media (min-width: $md) {
    background: $gray2;
    height: calc(100vh - 60px);
    margin: auto;
    overflow: auto;

    .container {
      max-width: 912px;
      margin-left: auto;
      margin-right: auto;

      .box {
        background: white;
        padding: 3rem 7rem;
        margin-top: 1rem;
      }
    }
  }
}

.expires {
  padding: 0 5px;
  display: inline-block;

  &.red {
    background: $primaryFaded;
    color: $primary;
  }
  &.blue {
    background: $secondaryFaded;
    color: $secondary;
  }
  &.green {
    background: $tertiaryFaded;
    color: $tertiary;
  }
}

.posts {
  white-space: nowrap;
  width: 100%;

  .posts-container {
    display: flex;
    width: max-content;
  }

  .post-container {
    &:not(:first-child) {
      margin-left: 1rem;
    }

    .post {
      width: 100%;
      height: 220px;
      background-size: cover;
      position: relative;
      background-position: center;

      .profile {
        width: 30px;
        margin: 5px;
      }

      .tag {
        position: absolute;
        bottom: 5px;
        right: 5px;
        color: white;
        font-weight: 600;
      }

    }

    img {
      width: 120px;
      height: 220px;
      object-fit: cover;
    }

    video {
      height: 220px;
    }

    .metrics {
      height: 40px;
      padding: 10px 0;
      margin-bottom: 1rem;

      .metric {
        display: flex;
        align-items: center;

        svg {
          margin-right: 8px;
          color: $secondary;
        }
      }
    }
  }

  & > :not(:first-child) {
    margin-left: 1rem;
  }
}

.popconfirm-reject {
  textarea.ant-input {
    height: 80px;
  }
}

.component {
  background: white;
  padding: $padding;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
}

.color-thumbnail {
  height: 14px;
  width: 14px;
  border: 1px solid black;
}

.logo-container {
  width: 50px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.box {
  border: solid $gray3 1px;
  border-radius: 5px;
  padding: 8px;
}

.full-width-mobile {
  @media (max-width: $sm) {
    margin-left: -$padding !important;
    margin-right: -$padding !important;
  }
}

.bg-ripple-half {
  background-image: url('/img/ripple-half.svg');
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}

.bg-ripple {
  background-image: url('/img/ripple.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.user-dropdown {
  display: flex;
  align-items: center;

  & > :not(:first-child) {
    margin-left: 1rem;
  }

  .profile-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}