.brand {
  .top {
    height: 60px;
    padding: 10px;
    display: flex;
    justify-content: center;

    img {
      height: 100%;
    }
  }

  .brand-home {
    .title {
      font-size: 40px;
      line-height: 52px;
      font-weight: 600;

      @media (max-width: $sm) {
        font-size: 28px;
        line-height: 38px;
      }
    }

    .main-container {
      @media (max-width: $sm) {
        padding-top: 0;
      }
    }

    .container {
      max-width: 1200px;


      .headline {
        margin-top: 4rem;
        p {
          font-size: 25px;
        }
        @media (max-width: $sm) {
          margin-top: 2rem;
          margin-bottom: 2rem;

          h1 {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 0;
          }

          p {
            font-size: 16px;
          }
        }
      }

      .header {
        position: relative;
        margin-bottom: 1rem;

        .header-graphic {
          margin-top: 1rem;
        }

        @media (min-width: $md) {
          .header-row {
            margin-right: -24px;
            overflow-x: hidden;
            position: relative;
          }
        }

        .background-image {
          background-size: cover;
          border-radius: 7px 0 0 7px;
          position: absolute;
          left: 200px;
          height: 100%;
          background-position: center;
          display: none;

          @media (min-width: $md) {
            display: block;
            left: 45%;
            width: 55%;
          }
        }

        @media (min-width: $md) {
          .cta {
            margin-left: 100px;
            margin-top: 60px;
          }
        }

        .read-more {
          width: 137px;
          transform: rotate(-90deg);
          transform-origin: top left;
          margin-left: -7px;
          position: absolute;
          bottom: -120px;

          p {
            text-align: right;
            margin-bottom: -5px;
          }

          svg {
            color: $primaryLight;
          }
        }
      }

      .previews {
        padding-left: 4%;
        padding-right: 4%;

        .social-preview {
          margin-bottom: 3rem;
        }
      }
    }

    .profiles {
      margin-top: 3rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      min-width: 450px;
    }

    .ul-right {
      margin-left: 3rem;
    }

    .quote {
      svg {
        width: 47px;
        height: 40px;
      }
    }

    .footer-logo {
      width: 72px;
    }
  }

  .small-centered {
    max-width: 360px;
    margin: auto;
    margin-top: 1rem;
  }

  .stories {
    @media (min-width: $md) {
      .box {
        padding: $padding;
      }
    }
  }
}

