.header-graphic {
  position: relative;
  margin-bottom: 20px;
  margin-right: 50px;
  width: fit-content;

  h1 {
    margin: 0;
  }

  .foreground {
    background: $secondary;
    padding: 12px 24px;
    position: relative;

    h1 {
      color: white;
    }
  }

  .background {
    background: $primaryLight;
    color: $primaryLight;
    padding: 12px 24px;
    position: absolute;
    top: 20px;
    left: 12px;
    right: -12px;

    h1 {
      color: inherit;
    }
  }
}
