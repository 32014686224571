.dashboard-home {
  height: 100%;
  display: grid;
  grid-gap: 32px;
  grid:
  'dashboard-top    dashboard-top'  max-content
  'superfans-chart  pending'        500px
  'story            story'          117px
  / 1fr 400px;
}

@media (max-width: 767px) {
  .dashboard-home {
    grid:
      'dashboard-top'      max-content
      'superfans-chart'    1fr
      'pending'            1fr
      'story'              117px;

    .component {
      margin-left: -$padding;
      margin-right: -$padding;
    }

  }
}
@media (max-width: 374px) {
  .title {
    button {
      display: none;
    }
  }
}

.dashboard-home {
  .component {
    background: white;
    padding: $padding;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.02);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
  }

  .legend {
    display: flex;
    align-items: center;
    margin: 0.7rem 0;

    p {
      margin-left: 5px;
    }

    .thumbnail {
      width: 12px;
      height: 12px;
      border-radius: 3px;
      margin-right: 3px;

      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  .date-selector {
    display: flex;
    justify-content: flex-end;
    height: 42px;
  }

  .pending {
    .pending-card {
      height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .profile {
        width: 75px;
        height: 75px;
        border-radius: 50%;
      }

      .quote-container {
        display: flex;
        align-items: center;
        flex-grow: 1;
      }

      .icon {
        color: $secondaryFaded;
      }

      .copy {
        height: 88px;
      }

    }
  }
  .control-dots {
    margin-bottom: 0;
  }
}
