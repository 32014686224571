.superfan-signup {
  .profile {
    display: flex;
    justify-content: center;
    position: relative;
    width: 74px;
    display: block;
    margin: auto;
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 2rem;

    &:focus {
      outline: none;
    }

    .edit {
      position: absolute;
      top: 40px;
      right: 0;
    }

    img {
      width: 64px;
      border-radius: 50%;
    }
  }

  .float-label {
    margin-bottom: 2rem;
  }

  .motivation {
    border-color: $secondary;
    color: $text;
  }

  textarea {
    font-weight: 500;
  }

  textarea::placeholder {
    color: $light-text;
    font-weight: 500;
  }

  .social-account {
    display: flex;
    justify-content: space-between;
    padding: 7px;
    border: 1px solid $gray4;
    border-radius: 8px;
    cursor: pointer;

    .left img {
      margin-right: 10px;
    }

    .right {
      color: $secondary;
    }

    &.connected {
      border-color: $tertiary;
      background: $tertiaryFaded2;

      .right {
        color: $primary;
      }
    }
  }

  .summary-section {
    .edit {
      cursor: pointer;
    }

    .box {
      display: flex;
      align-items: center;
      border-radius: 6px;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
      padding: 16px;
      margin-left: 26px;
      margin-top: 7px;

      .profile-image {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .icon {
        margin-right: 10px;
      }
    }
  }

  .uploaded-video {
      p {
        text-align: center;
      }

      video {
        max-width: 100%;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      }
  }
}

.connect-instagram {
  .instagram-profile {
    margin-bottom: 1rem;

    img {
      border-radius: 50%;
      max-width: 75px;
      margin-right: 10px;
    }
  }
}
